html{
  background-color:#3a3a3a;
  color:#fff;
  position:relative;
  margin:0;
  max-width: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  position:relative;
  margin: 0;
  padding: 0;
}

header{
  height:100px;
  position:fixed;
  width:100%;
  height:68px;
  margin:auto;
  top:0;
  z-index:3;
}

header #background{
  /* display:none; */
  height:100px;
  background-color:#424242;
  border-bottom:#b69632 solid 2px;
  position: absolute ;
  width:100%;
  z-index: -10;
}

.headerInsets{
  display:block;
  height:100px;
  margin:50px 0;
}

footer{
  display:block;
  bottom:0;
  height:auto;
  width:100%;
}

a{
  color:#fff;
  text-decoration:none;
}

.listLogo{
  display:none;
  width:100%;
}

.logo{
  width:330px;
}

#logoclear{
  display:none;
}

#logosolid{
  /* display:none; */
}

.Logolist{
  float:left;
  position:fixed;
}

.navList{
  padding-left:400px;
  font-family:arial;
}

.navList li{
  color:#fff;
  display: inline;
  font-size:20px;
  font-weight:bold;
  line-height:50px;
  padding:0px 10px 0px 10px;
  transition:0.3s;
}

.navList li:hover {
  transition:0.3s;
  border-bottom:#bb9532 solid 3px;
}


@media only screen and (max-width:1000px) {
  header{
      display:block;
      height:auto;
      position:relative;
  }

  header #background {
    display: none;
  }
  
  #logoclear{
      display:none;
  }
  
  #logosolid{
      display:none;
  }
  
  .listLogo{
      display:block;
  }
  
  .navList{
      padding:0;
      width:100%;
  }
  .navList li{
      border-bottom:#bb9532 solid 1px;
      display:block;
      text-align:center;
      width:100%;
  }
}

/* ----- Content ------ */

.bannerSection{
  width:100%;
}

.bannerSection img{
  width:100%;
}

h3{
  font-family:arial;
  color:#fff;
  font-size:30px;
}

.mainSection2{
  display: block;
  position: relative;
  padding:0 0 320px 0;
  width:100%;
}

.mainSection2 .contentContainer{
  margin:20px 40px;
}

.mainSection{
  border-top:#b69632 solid 5px;
  display: block;
  position: relative;
  padding:0 0 320px 0;
  width:100%;
}

.mainSection .contentContainer{
  margin:20px 40px;
}

.newsBox{
  border-bottom:#000 solid 2px;
}

.newsBoxImg img{
  width:100%;
}

@media only screen and (max-width:1000px) {
  .mainSection{
      padding:0;
  }
  .mainSection2{
      padding:0;
  }
}

/* -- ConcreteBatch -- */

.cboptImg{
  height:100%;
  width:100%;
}

.linkBtn{
  background-color:red;
  border-radius:5px;
  color:#fff;
  padding:5px;
  width:90%;
}

.linkBtn:hover{
  background-color:#242424;
}

/* Footer */

.footerContainer{
  position: relative;
  background-color:#424242;
  width:100%;
  height:auto;
}

.side{
  position:relative;
  float:left;
  width:300px;
  margin:0 20px 0 20px;
}

.side a{
  text-decoration:underline;
}

.side a:hover{
  color:#bb9532;
}

/* -- Slides -- */

.mySlides fade{
  position: relative;
  text-align: center;
}

#sliderHeader{
  color: #fff;
  font-size:30px;
  background-color:rgba(0,0,0,0.7);
  padding:1% 2% 1% 2%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
}

#pBanner{
  color: #fff;
  font-size:30px;
  background-color:rgba(0,0,0,0.7);
  padding:0.1% 1% 0.1% 1%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 30%);
  z-index:2;
}


/* Videos */

.videoContainer{
  border:#fff solid 2px;
  border-radius:20px;
  display:inline-block;
  height:250px;
  margin:10px 10px;
  overflow:hidden;
  width:250px;
}

.videoContainer:hover{
  cursor:pointer;
}

.videoContainer img{
  display:block;
  height:70%;
  width:100%;
  margin:auto;
}

.videoContainer .textContainer{
  display:block;
  height:10%;
  width:10%;
}

.videoContainer .textContainer p{
  font-size:20px;
  font-weight:bold;
  line-height:20px;
}

@media only screen and (max-width:1000px) {
  .videoContainer{
      border:0;
      border-bottom:#fff solid 2px;
      border-radius:0;
      height:400px;
      margin:0;
      width:100%;
  }
  .videoContainer .textContainer p{
      text-align:center;
  }
}

/* Video Detail Page */

video{
  height:300px;
  margin:auto 25%;
  width:50%;
}

